import { homePage } from "./esPages/home";
import { productPage } from "./esPages/product";
import { formContact } from "./esPages/formContact";
import { footerComponent } from "./esPages/footer";
import {
  navbarComponentMobile,
  navbarComponentDesktop,
} from "./esPages/navbar";

export const es = {
  homePage,
  productPage,
  formContact,
  navbarComponentMobile,
  navbarComponentDesktop,
  footerComponent,
};
