import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import COLORS from "../../styles/colors";
import background from "../../constants/images/backgroundHomeProduct.png";

const headerStyles = {
  sectionDesktop: {
    background: `linear-gradient(180deg, #324557 0%, rgba(50, 69, 87, 0.7) 100%), url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "0px 0px 300px",
  },
  sectionMobile: {
    background: `linear-gradient(180deg, #324557 0%, rgba(50, 69, 87, 0.7) 100%), url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "0px 0px 300px",
  },
  button: {
    background: COLORS.yellow_2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    padding: "12.5px 62.5px",
    margin: "92px auto 68px",
    border: "0px",
  },
  buttonMobile: {
    background: COLORS.yellow_2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    padding: "7.5px 20px",
    margin: "39px auto 0px",
    border: "0px",
  },
};

export const ProductContainer = ({ content }) => {
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={headerStyles.sectionDesktop}
        >
          <Grid item md={4}>
            {content.titleDesktop}
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                background: COLORS.white,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "30px",
              }}
            >
              <Grid item md={10} style={{ textAlign: "center" }}>
                {content.productCardDesktop.title}
              </Grid>
              <Grid item md={5} style={{ margin: "36px 0px 0px" }}>
                {content.productCardDesktop.image1}
              </Grid>
              <Grid item md={5}>
                {content.productCardDesktop.paragraph1}
              </Grid>
              <Grid item md={5}>
                {content.productCardDesktop.subtitle}
                {content.productCardDesktop.paragraph2}
              </Grid>
              <Grid item md={5} style={{ textAlign: "right" }}>
                {content.productCardDesktop.image2}
              </Grid>
              <Grid item md={10}>
                {content.productCardDesktop.paragraph3}
              </Grid>
              <Grid item md={10} style={{ textAlign: "center" }}>
                <NavLink exact to="/product">
                  <button style={headerStyles.button}>
                    {content.productCardDesktop.buttonText}
                  </button>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={headerStyles.sectionMobile}
        >
          <Grid item xs={10}>
            {content.titleMobile}
          </Grid>
          <Grid item xs={10}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                background: COLORS.white,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "30px",
                margin: "60px 0px",
                padding: "35px 0px",
              }}
            >
              <Grid item xs={10} style={{ textAlign: "center" }}>
                {content.productCardMobile.title}
                {content.productCardMobile.image1}
                {content.productCardMobile.paragraph1}
                {content.productCardMobile.subtitle}
                {content.productCardMobile.image2}
                {content.productCardMobile.paragraph2}
                {content.productCardMobile.paragraph3}
                <NavLink exact to="/product">
                  <button style={headerStyles.buttonMobile}>
                    {content.productCardMobile.buttonText}
                  </button>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
