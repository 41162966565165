import COLORS from "./colors";

const formStyles = {
  upperTitle: {
    color: COLORS.blueSky_2,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '500',
    fontSize: '24px',
    letterSpacing: "0.15em",
    margin: '0px'
  },
  title: {
    color: COLORS.white,
    fontFamily: "Archivo Black, sans-serif",
    fontWeight: '400',
    fontSize: '36px',
    letterSpacing: "-0.02em",
    margin: '21px 0px 0px' 
  },
  paragraph: {
    color: COLORS.white,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "300",
    fontSize: "24px",
    fontStyle: "italic",
    letterSpacing: "-0.02em",
    margin: '19px 0px 0px' 
  },
  textButton: {
    color: COLORS.blueSky,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "600",
    fontSize: "20px",
    letterSpacing: "0.0075em",
    margin: '0px 30px' 
  }
};

const navbarStyles = {
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignConten: "center",
    width: "100%",
    margin: '15px 0px 0px'
  },
  text: {
    color: COLORS.white,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '500',
    fontSize: '18px',
    margin: '0px'
  },
  icon: {
    maxWidth: "26px",
    maxHeight: "26px",
  }
};

const footerStyles = {
  title: {
    color: COLORS.white,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "700",
    fontSize: "18px",
    letterSpacing: "0.095em"
  }
}

export { formStyles, navbarStyles, footerStyles };
