import { API } from 'aws-amplify'

export const sendForm = (formData) => {
    const apiName = process.env.REACT_APP_NAME_ENDPOINT;
    const endpoint = '/contact';
    const options = {
        body: formData
    }

    return API.post(apiName, endpoint, options)
}