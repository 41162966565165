import COLORS from "./colors";

const ProductStyle = {
  title: {
    color: COLORS.white,
    fontSize: "36px",
    fontWeight: "bold",
    fontFamily: "Archivo, sans-serif",
    margin: "0px",
  },
  titleMobile: {
    color: COLORS.white,
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Archivo, sans-serif",
    margin: "0px",
  },
  titleHighlight: {
    color: COLORS.yellow,
  },
  paragraph: {
    color: COLORS.blue_1,
    fontFamily: "Barlow, sans-serif",
    fontSize: "24px",
    fontWeight: "400",
    margin: "10px 0px 0px 0px",
  },
  paragraphMobile: {
    color: COLORS.white,
    fontFamily: "Barlow, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    margin: "10px 0px 0px 0px",
  },
  descriptionTitleDesktop: {
    color: COLORS.blue_1,
    fontSize: "30px",
    fontWeight: "400",
    fontFamily: "Archivo Black, sans-serif",
    margin: "0px",
    padding: '0px 25px',
    textAlign: "center",
  },
  descriptionTitleMobile: {
    color: COLORS.blue_1,
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "Archivo Black, sans-serif",
    margin: "0px",
    textAlign: "center",
  },
  descriptionParagraphMobile: {
    color: COLORS.blue_1,
    fontFamily: "Barlow, sans-serif",
    fontSize: "13px",
    fontWeight: "400",
    margin: "20px 0px 0px 0px",
  },
  titleDescriptionDesktop: {
    color: COLORS.blue_2,
    fontSize: "36px",
    fontWeight: "400",
    fontFamily: "Archivo Black, sans-serif",
    margin: "0px",
    letterSpacing: "0.1em",
    textAlign: "center",
  },
  titleDescriptionMobile: {
    color: COLORS.blue_2,
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "Archivo Black, sans-serif",
    margin: "0px",
    textAlign: "center",
  },
  deviceDesktop: {
    height: "30vh",
    margin: "5px",
  },
  deviceMobile: {
    height: "18vh",
    margin: "10px 5px",
  },
  solutionTitleDesktop: {
    color: COLORS.blue_1,
    fontFamily: "Archivo, sans-serif",
    fontSize: "24px",
    fontWeight: "400",
    margin: "5%",
  },
  solutionTitleMobile: {
    color: COLORS.blue_1,
    fontFamily: "Archivo, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    margin: "0px",
  },
  solutionTitleHighlight: {
    fontWeight: "700",
  },
  solutionImageDesktop: {
    width: "90%",
    margin: "2vh 0px",
  },
  solutionImageMobile: {
    width: "80%",
    margin: "2vh 0px",
  },
  solutionImage: {
    height: "15vh",
    margin: "2vh 0px",
  },
  solutionParagraphDesktop: {
    color: COLORS.black,
    fontFamily: "Barlow, sans-serif",
    fontSize: "18px",
    fontWeight: "300",
    margin: "5% 5%",
  },
  productDetailsParagraph: {
    color: COLORS.blue_2,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "400",
    fontSize: "20px",
  },
  productDetailsModulesTitle: {
    color: COLORS.blue_2,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "600",
    fontSize: "18px",
  },
  productDetailsModulesParagraph: {
    color: COLORS.blue_2,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    margin: "1% 0px",
  },
  firstValue: {
    textAlign: "center",
    background: COLORS.yellow,
    padding: "50px 10px 10px",
    minHeight: "520px",
    width: "95%",
  },
  secondValue: {
    textAlign: "center",
    background: COLORS.white,
    padding: "50px 10px 10px",
    minHeight: "520px",
    width: "95%",
  },
  valueImage: {
    height: "15vh",
    margin: "2vh 0px",
  },
  valueTitle: {
    color: COLORS.blue_1,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "700",
    fontSize: "20px",
    margin: "2vh 20% ",
  },
  valueParagraph: {
    color: COLORS.blue_1,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "300",
    fontSize: "18px",
    margin: "1vh 15%",
  },
  forWhomTitle: {
    color: COLORS.yellow,
    fontFamily: "Archivo Black, sans-serif",
    fontWeight: "400",
    fontSize: "48px",
  },
  forWhomParagraph: {
    color: COLORS.white,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "500",
    fontSize: "20px",
    textAlign: "center",
  },
  forWhomHighligh: {
    fontWeight: "700",
  },
};

export { ProductStyle };
