import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { sendForm } from "../../services/FormService";
import { validatorMail } from "../../utils/validator";
import { useStyles, StyledButton, StyledTextFieldMobile } from "./Styles";

export const Mobile = ({ content }) => {
  const formStyles = useStyles();
  const [loading, setLoading] = useState(false);
  const [sendStatus, setSendStatus] = useState({ send: false, error: false });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSend = () => {
    setLoading(true);
    sendForm(formData)
      .then((response) => {
        setSendStatus({ send: true, error: false });
      })
      .catch((error) => {
        console.error(error);
        setSendStatus({ send: false, error: true });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorValue = false;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "name") {
      errorValue = value.length > 3;
    }

    if (name === "message") {
      errorValue = value.length > 1;
    }

    if (name === "email") {
      errorValue = validatorMail(value);
    }

    setFormErrors({ ...formErrors, [name]: !errorValue });
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ background: "#0E253C", paddingTop: "100px" }}
    >
      <Grid item xs={10}>
        {content.upperTitle}
        {content.title}
        {content.paragraph}
      </Grid>
      <Grid item xs={10}>
        {!loading && (
          <form autoComplete="on">
            <div>
              <StyledTextFieldMobile
                required
                error={formErrors.name}
                label={content.textfieldName}
                value={formData.name}
                name="name"
                onChange={handleChange}
                helperText={
                  formErrors.name ? "Ingrese a lo menos 3 letras." : ""
                }
              />
            </div>
            <div>
              <StyledTextFieldMobile
                required
                error={formErrors.email}
                label={content.textfieldEmail}
                value={formData.email}
                name="email"
                onChange={handleChange}
                helperText={formErrors.email ? "Ingrese un correo valido" : ""}
              />
            </div>
            <div>
              <StyledTextFieldMobile
                required
                error={formErrors.message}
                label={content.textfieldMessage}
                value={formData.message}
                name="message"
                onChange={handleChange}
                helperText={
                  formErrors.message ? "Ingrese a lo menos 1 letra." : ""
                }
              />
            </div>
            <div style={{ textAlign: "center", margin: "20px 0px" }}>
              <StyledButton
                onClick={handleSend}
                disabled={
                  formErrors.name || formErrors.email || formErrors.message
                }
              >
                {formErrors.name || formErrors.email || formErrors.message
                   ? content.textButtonAlert
                   : content.textButton}
              </StyledButton>
            </div>
          </form>
        )}
        {loading && !sendStatus.error && !sendStatus.send && (
          <h2 className={formStyles.messageFormSend}>Enviando mail...</h2>
        )}
        {sendStatus.error && (
          <h2 className={formStyles.messageFormError}>Tuvimos un error</h2>
        )}
        {sendStatus.send && (
          <h2 className={formStyles.messageFormSuccess}>
            Mensaje enviado con éxito
          </h2>
        )}
      </Grid>
    </Grid>
  );
};
