import React from "react";
import { Hidden, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Desktop, Mobile } from "../components/Navbar";
import title from "../constants/images/title.svg";
import logoDiscovery from "../constants/images/logoDiscovery.png";
import LanguageSelector from "../components/LanguageSelector";

const NavbarStyles = makeStyles((theme) => ({
  imgTitle: {
    margin: "0px",
    maxHeight: "25px",
    maxWidth: "100%",
  },
  imgTitleMobile: {
    margin: "0px",
    maxHeight: '6vh'
  },
}));

export const NavbarContainer = ({menuItemsMobile={}, menuItemsDesktop={}}) => {
  const classes = NavbarStyles();

  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-end"
          style={{ height: "10vh" }}
        >
          <Grid item xs={5} md={3}>
            <img src={title} alt="DISCOVERY" className={classes.imgTitle} />
          </Grid>
          <Grid item xs={5} md={6}>
            <Desktop content={menuItemsDesktop} />
          </Grid>
          {/* <Grid item xs={1}>
            <LanguageSelector />
          </Grid> */}
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ height: "10vh", marginTop: "30px" }}
        >
          <Grid item xs={9} md={4}>
            <img
              src={logoDiscovery}
              alt="Discovery Icon"
              className={classes.imgTitleMobile}
            />
          </Grid>
          <Grid item xs={1} md={6}>
            <Mobile LanguageSelectorComponent={LanguageSelector} content={menuItemsMobile} />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
