import { ProductStyle } from "../../../styles/productStyle";
import { productPageText } from "./enText";

import iPadHeaderProduct from "../../images/iPadHeaderProduct.png";
import iPhoneHeaderProduct from "../../images/iPhoneHeaderProduct.png";
import descriptionItem1 from "../../images/descriptionItem1.png";
import descriptionItem2 from "../../images/descriptionItem2.png";
import descriptionItem3 from "../../images/descriptionItem3.png";
import descriptionProduct from "../../images/descriptionProduct.png";
import rectangleCeleste from "../../images/rectangleCeleste.svg";
import rectangleYellow from "../../images/rectangleYellow.svg";
import rectangleWhite from "../../images/rectangleWhite.svg";
import value1 from "../../images/value1.png";
import value2 from "../../images/value2.png";

const productPage = {
  header: {
    titleDesktop: (
      <>
        <p style={ProductStyle.title}>{productPageText.header.title1}</p>
        <p style={ProductStyle.title}>
          {`${productPageText.header.title2} `}
          <span style={ProductStyle.titleHighlight}>
            {productPageText.header.title2Span}
          </span>
        </p>
      </>
    ),
    titleMobile: (
      <>
        <p style={ProductStyle.titleMobile}>{productPageText.header.title1}</p>
        <p style={ProductStyle.titleMobile}>
          {`${productPageText.header.title2} `}
          <span style={ProductStyle.titleHighlight}>
            {productPageText.header.title2Span}
          </span>
        </p>
      </>
    ),
    paragraphDesktop: (
      <p style={ProductStyle.paragraph}>{productPageText.header.paragraph}</p>
    ),
    paragraphMobile: (
      <p style={ProductStyle.paragraphMobile}>
        {productPageText.header.paragraph}
      </p>
    ),
  },
  description: {
    devices: {
      ipadDeviceDesktop: (
        <img
          src={iPadHeaderProduct}
          alt="ipadDevice"
          style={ProductStyle.deviceDesktop}
        />
      ),
      ipadDeviceMobile: (
        <img
          src={iPadHeaderProduct}
          alt="ipadDevice"
          style={ProductStyle.deviceMobile}
        />
      ),
      iphoneDeviceDesktop: (
        <img
          src={iPhoneHeaderProduct}
          alt="ipadDevice"
          style={ProductStyle.deviceDesktop}
        />
      ),
      iphoneDeviceMobile: (
        <img
          src={iPhoneHeaderProduct}
          alt="ipadDevice"
          style={ProductStyle.deviceMobile}
        />
      ),
    },
    principalParagraphDesktop: (
      <p style={ProductStyle.descriptionTitleDesktop}>
        <span style={ProductStyle.titleHighlight}>
          {`${productPageText.description.principalParagraphSpan} `}
        </span>
        {productPageText.description.principalParagraph}
      </p>
    ),
    principalParagraphMobile: (
      <p style={ProductStyle.descriptionTitleMobile}>
        <span style={ProductStyle.titleHighlight}>
          {`${productPageText.description.principalParagraphSpan} `}
        </span>
        {productPageText.description.principalParagraph}
      </p>
    ),
    paragraphDesktop: (
      <p style={ProductStyle.paragraph}>
        {productPageText.description.paragraph}
      </p>
    ),
    paragraphMobile: (
      <p style={ProductStyle.descriptionParagraphMobile}>
        {productPageText.description.paragraph}
      </p>
    ),
    titleDesktop: (
      <p style={ProductStyle.titleDescriptionDesktop}>
        {productPageText.description.title}
      </p>
    ),
    titleMobile: (
      <p style={ProductStyle.titleDescriptionMobile}>
        {productPageText.description.title}
      </p>
    ),
    stepSolutions: [
      {
        title: (
          <>
            <p style={ProductStyle.solutionTitleDesktop}>
              <span style={ProductStyle.solutionTitleHighlight}>
                {`${productPageText.description.solution1titleSpan} `}
              </span>
              <br />
              {productPageText.description.solution1title}
            </p>
          </>
        ),
        image: (
          <img
            src={descriptionItem1}
            alt="observaciones en terreno"
            style={ProductStyle.solutionImage}
          />
        ),
        paragraph: (
          <p style={ProductStyle.solutionParagraphDesktop}>
            {productPageText.description.solution1Paragraph}
          </p>
        ),
      },
      {
        title: (
          <>
            <p style={ProductStyle.solutionTitleDesktop}>
              {`${productPageText.description.solution2title1} `}
              <span style={ProductStyle.solutionTitleHighlight}>
                {productPageText.description.solution2title1Span}
              </span>
              {productPageText.description.solution2title2}
            </p>
          </>
        ),
        image: (
          <img
            src={descriptionItem2}
            alt="mapa de sectores"
            style={ProductStyle.solutionImage}
          />
        ),
        paragraph: (
          <p style={ProductStyle.solutionParagraphDesktop}>
            {productPageText.description.solution2Paragraph}
          </p>
        ),
      },
      {
        title: (
          <>
            <p style={ProductStyle.solutionTitleDesktop}>
              {`${productPageText.description.solution3Title} `}
              <span style={ProductStyle.solutionTitleHighlight}>
                <br />
                {productPageText.description.solution3TitleSpan}
              </span>
            </p>
          </>
        ),
        image: (
          <img
            src={descriptionItem3}
            alt="estandarización de riesgo"
            style={ProductStyle.solutionImage}
          />
        ),
        paragraph: (
          <p style={ProductStyle.solutionParagraphDesktop}>
            {productPageText.description.solution3Paragraph}
          </p>
        ),
      },
    ],
  },
  product: {
    productImageDesktop: (
      <img
        src={descriptionProduct}
        alt="producto discovery imac"
        style={ProductStyle.solutionImageDesktop}
      />
    ),
    productImageMobile: (
      <img
        src={descriptionProduct}
        alt="producto discovery imac"
        style={ProductStyle.solutionImageMobile}
      />
    ),
    paragraphDesktop: (
      <p style={ProductStyle.productDetailsParagraph}>
        {productPageText.product.paragraph}
      </p>
    ),
    paragraphMobile: (
      <p style={ProductStyle.descriptionParagraphMobile}>
        {productPageText.product.paragraph}
      </p>
    ),
    titleModulesDesktop: (
      <p style={ProductStyle.productDetailsModulesTitle}>
        {productPageText.product.titleModules}
      </p>
    ),
    titleModulesMobile: (
      <p
        style={{
          ...ProductStyle.productDetailsModulesTitle,
          fontSize: "12px",
        }}
      >
        {productPageText.product.titleModules}
      </p>
    ),
    modulesDesktop: [
      <p style={ProductStyle.productDetailsModulesParagraph}>
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module1}
      </p>,
      <p style={ProductStyle.productDetailsModulesParagraph}>
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module2}
      </p>,
      <p style={ProductStyle.productDetailsModulesParagraph}>
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module3}
      </p>,
      <p style={ProductStyle.productDetailsModulesParagraph}>
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module4}
      </p>,
    ],
    modulesMobile: [
      <p
        style={{
          ...ProductStyle.productDetailsModulesParagraph,
          fontSize: "12px",
        }}
      >
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module1}
      </p>,
      <p
        style={{
          ...ProductStyle.productDetailsModulesParagraph,
          fontSize: "12px",
        }}
      >
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module2}
      </p>,
      <p
        style={{
          ...ProductStyle.productDetailsModulesParagraph,
          fontSize: "12px",
        }}
      >
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module3}
      </p>,
      <p
        style={{
          ...ProductStyle.productDetailsModulesParagraph,
          fontSize: "12px",
        }}
      >
        <img
          src={rectangleCeleste}
          alt="rectangleCeleste_1"
          style={{ margin: "5px" }}
        />
        {productPageText.product.module4}
      </p>,
    ],
    highlightDesktop: (
      <p
        style={{
          ...ProductStyle.productDetailsModulesTitle,
          fontWeight: "700",
        }}
      >
        {productPageText.product.highlight}
      </p>
    ),
    highlightMobile: (
      <p
        style={{
          ...ProductStyle.productDetailsModulesTitle,
          fontWeight: "700",
          fontSize: "13px",
        }}
      >
        {productPageText.product.highlight}
      </p>
    ),
  },
  value: {
    titleDesktop: (
      <p style={{ ...ProductStyle.titleDescriptionDesktop, textAlign: "left" }}>
        {productPageText.value.title}
      </p>
    ),
    titleMobile: (
      <p style={{ ...ProductStyle.titleDescriptionDesktop, fontSize: "21px" }}>
        {productPageText.value.title}
      </p>
    ),
    values: [
      {
        styleDesktop: ProductStyle.firstValue,
        styleMobile: {
          ...ProductStyle.firstValue,
          minHeight: "0px",
          margin: "14px 0px",
          padding: "10px 10px 50px",
          width: "100%"
        },
        imageDesktop: (
          <img src={value2} alt="value_2" style={ProductStyle.valueImage} />
        ),
        imageMobile: (
          <img
            src={value2}
            alt="value_2"
            style={{ ...ProductStyle.valueImage }}
          />
        ),
        titleDesktop: (
          <p style={ProductStyle.valueTitle}>
            {productPageText.value.value1Title}
          </p>
        ),
        titleMobile: (
          <p style={{ ...ProductStyle.valueTitle, fontSize: "16px" }}>
            {productPageText.value.value1Title}
          </p>
        ),
        paragraphDesktop: (
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "3vh 10%",
              fontSize: "16px",
            }}
          >
            {productPageText.value.value1Paragraph}
          </p>
        ),
        paragraphMobile: (
          <p
            style={{
              ...ProductStyle.valueParagraph,
              fontWeight: "400",
              fontSize: "13px",
              margin: "3vh 5%",
            }}
          >
            {productPageText.value.value1Paragraph}
          </p>
        ),
        elementsDesktop: [
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "1vh 10%",
              fontSize: "16px",
            }}
          >
            <img
              src={rectangleWhite}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value1Element1}
          </p>,
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "1vh 10%",
              fontSize: "16px",
            }}
          >
            <img
              src={rectangleWhite}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value1Element2}
          </p>,
        ],
        elementsMobile: [
          <p style={{ ...ProductStyle.valueParagraph, fontSize: "12px" }}>
            <img
              src={rectangleWhite}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value1Element1}
          </p>,
          <p style={{ ...ProductStyle.valueParagraph, fontSize: "12px" }}>
            <img
              src={rectangleWhite}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value1Element2}
          </p>,
        ],
      },
      {
        styleDesktop: ProductStyle.secondValue,
        styleMobile: {
          ...ProductStyle.secondValue,
          minHeight: "0px",
          margin: "14px 0px",
          padding: "10px 10px 50px",
          width: "100%"
        },
        imageDesktop: (
          <img src={value1} alt="value_1" style={ProductStyle.valueImage} />
        ),
        imageMobile: (
          <img
            src={value1}
            alt="value_2"
            style={{ ...ProductStyle.valueImage }}
          />
        ),
        titleDesktop: (
          <p style={ProductStyle.valueTitle}>
            {productPageText.value.value2Title}
          </p>
        ),
        titleMobile: (
          <p style={{ ...ProductStyle.valueTitle, fontSize: "16px" }}>
            {productPageText.value.value2Title}
          </p>
        ),
        paragraphDesktop: (
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "3vh 10%",
              fontSize: "16px",
            }}
          >
            {productPageText.value.value2Paragraph}
          </p>
        ),
        paragraphMobile: (
          <p
            style={{
              ...ProductStyle.valueParagraph,
              fontWeight: "400",
              fontSize: "13px",
              margin: "3vh 5%",
            }}
          >
            {productPageText.value.value2Paragraph}
          </p>
        ),
        elementsDesktop: [
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "1vh 10%",
              fontSize: "16px",
            }}
          >
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value1Element1}
          </p>,
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "1vh 10%",
              fontSize: "16px",
            }}
          >
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value2Element2}
          </p>,
          <p
            style={{
              ...ProductStyle.valueParagraph,
              margin: "1vh 10%",
              fontSize: "16px",
            }}
          >
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value2Element3}
          </p>,
        ],
        elementsMobile: [
          <p style={{ ...ProductStyle.valueParagraph, fontSize: "12px" }}>
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value2Element1}
          </p>,
          <p style={{ ...ProductStyle.valueParagraph, fontSize: "12px" }}>
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value2Element2}
          </p>,
          <p
            style={{
              ...ProductStyle.valueParagraph,
              fontSize: "12px",
            }}
          >
            <img
              src={rectangleYellow}
              alt="rectangleCeleste_1"
              style={{ margin: "5px", color: "yellow" }}
            />
            {productPageText.value.value2Element3}
          </p>,
        ],
      },
    ],
  },
  forWhom: {
    titleDesktop: (
      <p style={ProductStyle.forWhomTitle}>{productPageText.forWhom.title}</p>
    ),
    titleMobile: (
      <p style={{ ...ProductStyle.forWhomTitle, fontSize: "21px" }}>
        {productPageText.forWhom.title}
      </p>
    ),
    paragraphDesktop: (
      <p style={ProductStyle.forWhomParagraph}>
        {`${productPageText.forWhom.paragraph} `}
        <span style={ProductStyle.forWhomHighligh}>
          <br />
          <br />
          {productPageText.forWhom.paragraphSpan}
        </span>
      </p>
    ),
    paragraphMobile: (
      <p style={{ ...ProductStyle.forWhomParagraph, fontSize: "13px" }}>
        {`${productPageText.forWhom.paragraph} `}
        <span style={ProductStyle.forWhomHighligh}>
          <br />
          <br />
          {productPageText.forWhom.paragraphSpan}
        </span>
      </p>
    ),
  },
};

export { productPage };
