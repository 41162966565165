import { formStyles } from "../../../styles/sharedStyle";
import { formContactText } from "./enText";

const formContact = {
  desktop: {
    upperTitle: (
      <p style={formStyles.upperTitle}>{formContactText.upperTitle}</p>
    ),
    title: <p style={formStyles.title}>{formContactText.title}</p>,
    paragraph: <p style={formStyles.paragraph}>{formContactText.paragraph}</p>,
    textfieldName: formContactText.textfieldName,
    textfieldEmail: formContactText.textfieldEmail,
    textfieldMessage: formContactText.textfieldMessage,
    textButton: (
      <p style={formStyles.textButton}>{formContactText.textButton}</p>
    ),
    textButtonAlert: (
      <p style={formStyles.textButton}>{formContactText.textButtonAlert}</p>
    ),
  },
  mobile: {
    upperTitle: (
      <p style={{ ...formStyles.upperTitle, fontSize: "18px" }}>
        {formContactText.upperTitle}
      </p>
    ),
    title: (
      <p style={{ ...formStyles.title, fontSize: "30px", marginTop: "7px" }}>
        {formContactText.title}
      </p>
    ),
    paragraph: (
      <p style={{ ...formStyles.paragraph, fontSize: "18px" }}>
        {formContactText.paragraph}
      </p>
    ),
    textfieldName: formContactText.textfieldName,
    textfieldEmail: formContactText.textfieldEmail,
    textfieldMessage: formContactText.textfieldMessage,
    textButton: (
      <p style={{ ...formStyles.paragraph, fontSize: "18px" }}>
        {formContactText.textButton}
      </p>
    ),
    textButtonAlert: (
      <p style={{ ...formStyles.paragraph, fontSize: "18px" }}>
        {formContactText.textButtonAlert}
      </p>
    ),
  },
};

export { formContact };
