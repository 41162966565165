import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { DescriptionSolution } from "../../components/Product/DescriptionSolution";
import triangleRightYellow from "../../constants/images/triangleRightYellow.svg";
import triangleBottomYellow from "../../constants/images/triangleBottomYellow.svg";

export const Description = ({ content }) => {
  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid item xs={10}>
        <Hidden smDown>
          <div style={{ height: "30vh", marginTop: "-25vh" }}>
            {content.devices.ipadDeviceDesktop}
            {content.devices.iphoneDeviceDesktop}
          </div>
        </Hidden>
        <Hidden mdUp>
          <div
            style={{ textAlign: "center", height: "20vh", marginTop: "-10vh" }}
          >
            {content.devices.ipadDeviceMobile}
            {content.devices.iphoneDeviceMobile}
          </div>
        </Hidden>
      </Grid>

      <Hidden smDown>
        <Grid item xs={10} md={5} style={{ marginTop: "15vh" }}>
          {content.principalParagraphDesktop}
        </Grid>
        <Grid item md={1} />
        <Grid item xs={10} md={5} style={{ marginTop: "15vh" }}>
          {content.paragraphDesktop}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={10} md={4} style={{ marginTop: "5vh" }}>
          {content.principalParagraphMobile}
        </Grid>
        <Grid item xs={10} md={4} style={{ marginTop: "3vh" }}>
          {content.paragraphMobile}
        </Grid>
      </Hidden>

      <Hidden smDown>
        <Grid item xs={10} style={{ margin: "15vh 0px" }}>
          {content.titleDesktop}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={10} style={{ margin: "10vh 0px 5vh" }}>
          {content.titleMobile}
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="flex-start"
          style={{margin: "0px 0px 10vh"}}
        >
          {content.stepSolutions.map((step, index) => (
            <>
              <Grid key={`step_${index}`} item xs={10} md={3}>
                <DescriptionSolution content={step} />
              </Grid>
              {index !== 2 && (
                <Grid item xs={10} md={1} style={{ textAlign: "center" }}>
                  <Hidden smDown>
                    <img
                      src={triangleRightYellow}
                      alt="triangle right yellow"
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <img
                      src={triangleBottomYellow}
                      alt="triangle bottom yellow"
                      style={{ margin: "60px auto 45px" }}
                    />
                  </Hidden>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
