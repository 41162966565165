import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { List, ListItem, SwipeableDrawer, Grid } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import colors from "../../styles/colors";
import { useStyles } from "./Styles";
import logoDiscoveryFull from "../../constants/images/logoDiscoveryFull.svg";

export const Mobile = ({ LanguageSelectorComponent, content }) => {
  const { links, contact } = content;

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const scrollToContact = () => {
    window.scrollTo(0, 3000);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ backgroundColor: "#324557", height: "100%" }}
    >
      <List>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ padding: "45px 0px" }}
        >
          <Grid item xs={9} style={{ textAlign: "center" }}>
            <img
              src={logoDiscoveryFull}
              alt="Logo Dicovery Full"
              style={{ maxHeight: "178px", width: "80%", marginBottom: "55px" }}
            />
          </Grid>
          {links.map((item, index) => (
            <Grid item xs={10}>
              <ListItem button key={item.title} style={{ width: "100%" }}>
                <NavLink
                  key={`navbarItem-${index}`}
                  activeClassName={classes.navlinkActiveMobile}
                  className="nav-item nav-link"
                  exact
                  to={item.to}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                >
                  {item.title}
                </NavLink>
              </ListItem>
            </Grid>
          ))}
          <Grid item xs={10} style={{padding: '8px 16px'}}>
            <div onClick={scrollToContact}>{contact.title}</div>
          </Grid>
          {/* <Grid item xs={4} style={{ paddingTop: "20vh", textAlign: "center" }}>
            <LanguageSelectorComponent />
          </Grid> */}
        </Grid>
      </List>
    </div>
  );

  return (
    <>
      <MenuIcon
        style={{
          fontSize: "30px",
          color: colors.white,
        }}
        onClick={toggleDrawer("right", true)}
      />
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
};
