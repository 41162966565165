import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { LanguageContext } from "../contexts/LanguageContext";
import { HeaderContainer } from "../containers/home/Header";
import { AboutContainer } from "../containers/home/About";
import { ProductContainer } from "../containers/home/ProductDetails";
import { PartnersContainer } from "../containers/home/partners";
import { FormContainer } from "../containers/FormContainer";
import { FooterContainer } from "../containers/FooterContainer";

export const Home = () => {
  const { dictionary } = useContext(LanguageContext);
  const { header, about, product, partners } = dictionary.homePage;
  const { navbarComponentMobile, navbarComponentDesktop, footerComponent } = dictionary;

  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item>
        <HeaderContainer
          content={header}
          navbarContent={navbarComponentMobile}
          navbarContentDesktop={navbarComponentDesktop}
        />
      </Grid>

      <Grid item>
        <AboutContainer content={about} />
      </Grid>

      <Grid item>
        <ProductContainer content={product} />
      </Grid>

      <Grid item>
        <PartnersContainer content={partners} />
      </Grid>

      <Grid item>
        <FormContainer />
      </Grid>

      <Grid item>
        <FooterContainer content={footerComponent} />
      </Grid>
    </Grid>
  );
};
