import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { LanguageContext } from "../contexts/LanguageContext";
import { HeaderProductContainer } from "../containers/product/HeaderProduct";
import { Description } from "../containers/product/Description";
import { ProductDetails } from "../containers/product/ProductDetails";
import { Value } from "../containers/product/Value";
import { ForWhom } from "../containers/product/ForWhom";
import { FormContainer } from "../containers/FormContainer";
import { FooterContainer } from "../containers/FooterContainer";

export const Product = () => {
  const { pathname } = useLocation();
  const { dictionary } = useContext(LanguageContext);
  const { header, description, product, value, forWhom } =
    dictionary.productPage;
  const { navbarComponentMobile, navbarComponentDesktop, footerComponent } = dictionary;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item>
        <HeaderProductContainer
          content={header}
          navbarContent={navbarComponentMobile}
          navbarContentDesktop={navbarComponentDesktop}
        />
      </Grid>

      <Grid item>
        <Description content={description} />
      </Grid>

      <Grid item>
        <ProductDetails content={product} />
      </Grid>

      <Grid item>
        <Value content={value} />
      </Grid>

      <Grid item>
        <ForWhom content={forWhom} />
      </Grid>

      <Grid item>
        <FormContainer />
      </Grid>

      <Grid item>
        <FooterContainer content={footerComponent} />
      </Grid>
    </Grid>
  );
};
