import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../styles/colors";
import background from "../../constants/images/backgroundPartner.png";

const headerStyles = {
  sectionDesktop: {
    background: `linear-gradient(180deg, rgba(50, 69, 87, 0.6) 0%, rgba(50, 69, 87, 0.6) 100%), url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: '40vh'
  },
  sectionMobile: {
    background: `linear-gradient(180deg, rgba(50, 69, 87, 0.6) 0%, rgba(50, 69, 87, 0.6) 100%), url(${background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  partnerHighlight: {
    background: COLORS.white,
    borderRadius: "10px",
    padding: "60px 0px",
    margin: "-180px auto 0px",
  },
  buttonMobile: {
    background: COLORS.yellow_2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    padding: "7.5px 20px",
    margin: "39px auto 0px",
    border: "0px",
  },
};

export const PartnersContainer = ({ content }) => {
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={headerStyles.sectionDesktop}
        >
          <Grid item md={10} style={{marginTop: '-150px'}}>
            {content.titleDesktop}
          </Grid>
          <Grid item md={10}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              style={headerStyles.partnerHighlight}
            >
              {content.partnersDesktop.map((partner, index) => (
                <Grid item md={2} key={`partner_${index}`} style={{ textAlign: "center" }}>
                  {partner}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={headerStyles.sectionMobile}
        >
          <Grid item xs={10} style={{marginTop: '-250px'}}>
            {content.titleMobile}
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              style={headerStyles.partnerHighlight}
            >
              {content.partnersDesktop.map((partner, index) => (
                <Grid item xs={5} md={2} key={`partner_${index}`} style={{ textAlign: "center" }}>
                  {partner}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      
    </>
  );
};
