import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";

const useStyles = makeStyles((theme) => ({
    paragraph: {
      color: colors.white,
      fontFamily: "Archivo, sans-serif",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "300",
      textAlign: "center",
      letterSpacing: "0.1em",
      margin: '0px'
    },
    upperTitle: {
      fontSize: "18px",
      fontWeight: "300",
      letterSpacing: "0.1em"
    },
    title: {
      fontWeight: "300",
      letterSpacing: "0.1em"
    },
    navLink: {
      color: "inherit",
      padding: "0px 5px",
      textDecoration: "none",
    },
    navlinkActiveDesktop: {
      borderBottom: "4px solid #EEAE38",
    },
    navlinkActiveMobile: {
      filter: "invert(40%) sepia(70%) saturate(700%) hue-rotate(351deg) brightness(100%) contrast(60%)"
    },
  }));

  export { useStyles };
