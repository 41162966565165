const COLORS = {
  blue_1: "#00203A",
  blue_2: "#324557",
  blueSky: "#98BAC0",
  blueSky_2: "#A1B9BE",
  black: "#000000",
  gray: "#B7C6C6",
  gray_2: "#4F4F4F",
  gray_3: "#E5E5E5",
  gray_4: "#BDBDBD",
  gray_5: "#E0E0E0",
  gray_6: "#F2F2F2",
  green_1: "#1F7977",
  green_2: "#7CBC94",
  white: "#F0F0F0",
  yellow: "#EEAE38",
  yellow_2: "#DDAC43"
};

export default COLORS
