import React, { useContext } from "react";
import { Hidden } from "@material-ui/core";
import { Desktop, Mobile } from '../components/Form';
import { LanguageContext } from "../contexts/LanguageContext";


export const FormContainer = () => {
  const { dictionary } = useContext(LanguageContext);
  const { desktop, mobile } = dictionary.formContact;

  return (
    <>
      <Hidden mdUp>
        <Mobile content={mobile}/>
      </Hidden>
      <Hidden smDown>
        <Desktop content={desktop} />
      </Hidden>
    </>
  );
};
