import COLORS from "./colors";

const HomeStyle = {
  titleImgDesktop: {
    width: "90%"
  },
  titleImgMobile: {
    width: "75%"
  },
  headerParagraphDesktop: {
    color: COLORS.yellow,
    fontFamily: "Barlow, sans-serif",
    fontWeight: "500",
    fontSize: "24px",
    textAlign: 'right'
  },
  aboutTitle: {
    color: COLORS.blueSky,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '700',
    fontSize: '24px',
    letterSpacing: "0.1em",
  },
  aboutParagraph: {
    color: COLORS.white,
    fontFamily: "Barlow, sans-serif",
    fontWeight: '400',
    fontSize: '20px',
  },
  aboutParagraphHighlight: {
    fontWeight: '700',
  },
  productTitle: {
    color: COLORS.white,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '400',
    fontSize: '38px',
    letterSpacing: "0.1em",
    margin: '0px'
  },
  productTitleBlack: {
    color: COLORS.white,
    fontFamily: "Archivo Black, sans-serif",
    fontWeight: '400',
    fontSize: '38px',
    letterSpacing: "0.1em",
    margin: '0px'
  },
  productCardTitleDesktop: {
    maxWidth: '50%',
    margin: '36px auto 0px'
  },
  productCardTitleMobile: {
    maxWidth: '80%',
    margin: '0px auto 43px'
  },
  productCardImgDesktop: {
    maxWidth: '90%',
  },
  productParagraph: {
    color: COLORS.black,
    fontFamily: "Barlow, sans-serif",
    fontWeight: '400',
    fontSize: '18px',
  },
  productSubtitle: {
    color: COLORS.blueSky_2,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '600',
    fontSize: '24px',
    letterSpacing: "0.1em",
    margin: '47px 0px 0px'
  },
  productTextButton: {
    color: COLORS.white,
    fontFamily: "Archivo, sans-serif",
    fontWeight: '600',
    fontSize: '18px',
    letterSpacing: "0.02em",
    margin: '0px'
  },
  partnerImgDesktop: {
    width: '100%'
  }
};

export { HomeStyle };
