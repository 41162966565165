import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { NavbarContainer } from "../NavbarContainer";
import logoProductoDiscovery from "../../constants/images/logoProductoDiscovery.png";
import backgroundHeader from "../../constants/images/backgroundProduct.png";

const headerStyles = {
  section: {
    height: "90vh",
    background: `linear-gradient(180deg, #324557 16.15%, rgba(50, 69, 87, 0.61) 78.2%, rgba(50, 69, 87, 0) 100%), url(${backgroundHeader})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  logo: { height: "10vh" },
  content: { height: "70vh", display: "flex", alignItems: "center" },
  sectionBottom: { height: "20vh" },
};

export const HeaderProductContainer = ({ content, navbarContent, navbarContentDesktop }) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      style={headerStyles.section}
    >
      <Grid item xs={12}>
        <NavbarContainer menuItemsMobile={navbarContent} menuItemsDesktop={navbarContentDesktop} />
      </Grid>
      <Grid item xs={12} style={headerStyles.content}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={1} />
          <Hidden smDown>
            <Grid item md={3}>
              {content.titleDesktop}
              {content.paragraphDesktop}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={10}>
              {content.titleMobile}
              {content.paragraphMobile}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid item xs={12} style={headerStyles.sectionBottom}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
        >
          <Grid item xs={1} />
          <Hidden smDown>
            <Grid item xs={1}>
              <img
                src={logoProductoDiscovery}
                alt="iPadHeader"
                style={headerStyles.logo}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
