import React, { useContext } from "react";

import { LanguageContext } from "../contexts/LanguageContext";

const stylesSelector = {
  button: {
    cursor: "pointer",
    background: "none",
    border: "none",
  },
  selector: {
    color: "#FFFFFF",
    margin: "0px",
  },
  paragraph: {
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Barlow, sans-serif",
    margin: "0px",
  },
};

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = (value) => {
    userLanguageChange(value);
  };

  return (
    <div style={stylesSelector.selector}>
      <button
        onClick={(e) => handleLanguageChange("en")}
        style={stylesSelector.button}
      >
        <p
          style={{
            ...stylesSelector.paragraph,
            fontWeight: `${userLanguage === "en" ? "600" : "200"}`,
          }}
        >
          EN
        </p>
      </button>{" "}
      |{" "}
      <button
        onClick={(e) => handleLanguageChange("es")}
        style={stylesSelector.button}
      >
        <p
          style={{
            ...stylesSelector.paragraph,
            fontWeight: `${userLanguage === "es" ? "600" : "200"}`,
          }}
        >
          ES
        </p>
      </button>
    </div>
  );
}
