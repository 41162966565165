import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import backgroundForWhom from "../../constants/images/backgroundForWhom.png";

const styles = {
  section: {
    padding: "15vh 20% 10vh 20%",
    background: `linear-gradient(to bottom, rgba(0,32,58,0.7) 0%, rgba(0,32,58,0.7) 100%), url(${backgroundForWhom})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
  },
};

export const ForWhom = ({ content }) => {
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{...styles.section, marginTop: '10vh'}}
        >
          <Grid item xs={10}>
            {content.titleDesktop}
            {content.paragraphDesktop}
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{...styles.section, padding: "15vh 0% 10vh"}}
        >
          <Grid item xs={10}>
            {content.titleMobile}
            {content.paragraphMobile}
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
