import { HomeStyle } from "../../../styles/homeStyle";
import { homePageText } from "./enText";

import iPadHeaderProduct from "../../images/iPadHeaderProduct.png";
import meetDiscovery from "../../images/meetDiscovery.svg";
import findOutDiscovery from "../../images/findOutDiscovery.svg";
import workersDesktop from "../../images/workersDesktop.png";
import workersMobile from "../../images/workersMobile.png";
import discoveryGraph from "../../images/discoveryGraph.png";
import productDiscovery from "../../../constants/images/productDiscoveryES.svg";
import partner1 from "../../images/partner1.png";
import partner2 from "../../images/partner2.png";

const homePage = {
  header: {
    titleDesktop: (
      <img
        src={findOutDiscovery}
        alt="Descubre Discovery"
        style={HomeStyle.titleImgDesktop}
      />
    ),
    titleMobile: (
      <img
        src={findOutDiscovery}
        alt="Descubre Discovery"
        style={HomeStyle.titleImgMobile}
      />
    ),
    paragraphDesktop: (
      <p style={HomeStyle.headerParagraphDesktop}>
        {homePageText.header.headerParagraph}
      </p>
    ),
    paragraphMobile: (
      <p
        style={{
          ...HomeStyle.headerParagraphDesktop,
          fontSize: "15px",
          textAlign: "left",
        }}
      >
        {homePageText.header.headerParagraph}
      </p>
    ),
  },
  about: {
    imgDesktop: <img src={workersDesktop} alt="workers" style={{margin: '-100px', maxWidth: '100%'}} />,
    imgMobile: (
      <img src={workersMobile} alt="workers" style={{ maxWidth: "100%" }} />
    ),
    title: <img src={meetDiscovery} alt="discover discovery" />,
    aboutsDesktop: [
      {
        title: (
          <p style={HomeStyle.aboutTitle}>{homePageText.about.about1Title}</p>
        ),
        paragraph: (
          <p style={HomeStyle.aboutParagraph}>
            {`${homePageText.about.about1Paragraph1} `}
            <span style={HomeStyle.aboutParagraphHighlight}>
              {`${homePageText.about.about1ParagraphSpan1} `}
            </span>
            {`${homePageText.about.about1Paragraph2} `}
            <span
              style={HomeStyle.aboutParagraphHighlight}
            >{`${homePageText.about.about1ParagraphSpan2} `}</span>
            {homePageText.about.about1Paragraph3}
          </p>
        ),
      },
      {
        title: (
          <p style={HomeStyle.aboutTitle}>{homePageText.about.about2Title}</p>
        ),
        paragraph: (
          <p style={HomeStyle.aboutParagraph}>
            {homePageText.about.about2Paragraph}
          </p>
        ),
      },
    ],
    aboutsMobile: [
      {
        title: (
          <p style={{ ...HomeStyle.aboutTitle, fontSize: "16px" }}>
            {homePageText.about.about1Title}
          </p>
        ),
        paragraph: (
          <p style={{ ...HomeStyle.aboutParagraph, fontSize: "13px" }}>
            {`${homePageText.about.about1Paragraph1} `}
            <span style={HomeStyle.aboutParagraphHighlight}>
              {`${homePageText.about.about1ParagraphSpan1} `}
            </span>
            {`${homePageText.about.about1Paragraph2} `}
            <span
              style={HomeStyle.aboutParagraphHighlight}
            >{`${homePageText.about.about1ParagraphSpan2} `}</span>
            {homePageText.about.about1Paragraph3}
          </p>
        ),
      },
      {
        title: (
          <p style={{ ...HomeStyle.aboutTitle, fontSize: "16px" }}>
            {homePageText.about.about2Title}
          </p>
        ),
        paragraph: (
          <p style={{ ...HomeStyle.aboutParagraph, fontSize: "13px" }}>
            {homePageText.about.about2Paragraph}
          </p>
        ),
      },
    ],
  },
  product: {
    titleDesktop: (
      <>
        <p style={HomeStyle.productTitle}>{homePageText.product.title1}</p>
        <p style={HomeStyle.productTitleBlack}>{homePageText.product.title2}</p>
      </>
    ),
    titleMobile: (
      <>
        <p style={{ ...HomeStyle.productTitle, fontSize: "21px" }}>
          {homePageText.product.title1}
        </p>
        <p style={{ ...HomeStyle.productTitleBlack, fontSize: "21px" }}>
          {homePageText.product.title2}
        </p>
      </>
    ),
    productCardDesktop: {
      title: (
        <img
          src={productDiscovery}
          alt="producto Discovery"
          style={HomeStyle.productCardTitleDesktop}
        />
      ),
      image1: (
        <img
          src={iPadHeaderProduct}
          alt="discovery tablet"
          style={HomeStyle.productCardImgDesktop}
        />
      ),
      image2: (
        <img
          src={discoveryGraph}
          alt="discovery graph"
          style={HomeStyle.productCardImgDesktop}
        />
      ),
      subtitle: (
        <p style={HomeStyle.productSubtitle}>{homePageText.product.subtitle}</p>
      ),
      paragraph1: (
        <p style={HomeStyle.productParagraph}>
          {homePageText.product.paragraph1}
        </p>
      ),
      paragraph2: (
        <p style={HomeStyle.productParagraph}>
          {homePageText.product.paragraph2}
        </p>
      ),
      paragraph3: (
        <p style={HomeStyle.productParagraph}>
          {homePageText.product.paragraph3}
        </p>
      ),
      buttonText: (
        <p style={HomeStyle.productTextButton}>
          {homePageText.product.buttonText}
        </p>
      ),
    },
    productCardMobile: {
      title: (
        <img
          src={productDiscovery}
          alt="producto Discovery"
          style={HomeStyle.productCardTitleMobile}
        />
      ),
      image1: (
        <img
          src={iPadHeaderProduct}
          alt="discovery tablet"
          style={HomeStyle.productCardImgDesktop}
        />
      ),
      image2: (
        <img
          src={discoveryGraph}
          alt="discovery graph"
          style={HomeStyle.productCardImgDesktop}
        />
      ),
      subtitle: (
        <p
          style={{
            ...HomeStyle.productSubtitle,
            fontSize: "16px",
            marginBottom: "26px",
          }}
        >
          {homePageText.product.subtitle}
        </p>
      ),
      paragraph1: (
        <p style={{ ...HomeStyle.productParagraph, fontSize: "12px" }}>
          {homePageText.product.paragraph1}
        </p>
      ),
      paragraph2: (
        <p style={{ ...HomeStyle.productParagraph, fontSize: "12px" }}>
          {homePageText.product.paragraph2}
        </p>
      ),
      paragraph3: (
        <p style={{ ...HomeStyle.productParagraph, fontSize: "12px" }}>
          {homePageText.product.paragraph3}
        </p>
      ),
      buttonText: (
        <p style={HomeStyle.productTextButton}>
          {homePageText.product.buttonText}
        </p>
      ),
    },
  },
  partners: {
    titleDesktop: (
      <>
        <p style={HomeStyle.productTitle}>{homePageText.partners.title1}</p>
        <p style={HomeStyle.productTitleBlack}>
          {homePageText.partners.title2}
        </p>
      </>
    ),
    titleMobile: (
      <>
        <p style={{ ...HomeStyle.productTitle, fontSize: "21px" }}>
          {homePageText.partners.title1}
        </p>
        <p style={{ ...HomeStyle.productTitleBlack, fontSize: "21px" }}>
          {homePageText.partners.title2}
        </p>
      </>
    ),
    partnersDesktop: [
      <img
        src={partner1}
        alt="collahuasi icon"
        style={HomeStyle.partnerImgDesktop}
      />,
      <img
        src={partner2}
        alt="corfo icon"
        style={HomeStyle.partnerImgDesktop}
      />,
    ],
  },
};

export { homePage };
