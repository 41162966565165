import React from "react";
import { Grid, Hidden } from "@material-ui/core";

const styles = {
  section: {
    padding: "5vh 0px 0px",
  },
};

export const Value = ({ content }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      style={styles.section}
    >
      <Grid item xs={10} md={2}>
        <Hidden smDown>{content.titleDesktop}</Hidden>
        <Hidden mdUp>{content.titleMobile}</Hidden>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          direction="row-reverse"
          justify="space-around"
          alignItems="center"
          style={styles.section}
        >
          <Hidden smDown>
            {content.values.map((value, index) => (
              <Grid
                key={`value_${index}`}
                item
                xs={12}
                md={6}
                style={value.styleDesktop}
              >
                {value.imageDesktop}
                {value.titleDesktop}
                {value.paragraphDesktop}
                {value.elementsDesktop.map((element, index) => (
                  <div key={`element_${index}`} style={{ textAlign: "left" }}>
                    {element}
                  </div>
                ))}
              </Grid>
            ))}
          </Hidden>
          <Hidden mdUp>
            {content.values.map((value, index) => (
              <Grid
                key={`value_${index}`}
                item
                xs={12}
                md={6}
                style={value.styleMobile}
              >
                {value.imageMobile}
                {value.titleMobile}
                {value.paragraphMobile}
                {value.elementsMobile.map((element, index) => (
                  <div key={`element_${index}`} style={{ textAlign: "left" }}>
                    {element}
                  </div>
                ))}
              </Grid>
            ))}
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};
