import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import title from "../constants/images/title.svg";
import locationIcon from "../constants/images/locationIcon.svg";
import mailIcon from "../constants/images/mailIcon.svg";
import phoneIcon from "../constants/images/phoneIcon.svg";
import backgroundFooter from "../constants/images/backgroundFooter.png";

const styles = {
  paragraph: {
    color: "#FFFFFF",
    fontFamily: "Barlow, sans-serif",
    fontSize: "18px",
    fontWeight: "400",
    margin: "0px",
  },
  paragraphMobile: {
    color: "#FFFFFF",
    fontFamily: "Barlow, sans-serif",
    fontSize: "13px",
    fontWeight: "400",
    margin: "0px",
  },
  icon: {
    width: "28px",
    margin: "0px 34px 0px 0px",
  },
  iconMobile: {
    width: "16px",
    margin: "0px 13px 0px 0px",
  },
  imgTitle: {
    margin: "0px",
    maxHeight: "25px",
    maxWidth: "100%",
    opacity: "50%"
  },
  section: {
    background: `linear-gradient(to bottom, rgba(50,69,87,0.8) 0%, rgba(50,69,87,0.8) 100%), url(${backgroundFooter})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  items: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  itemsMobile: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: '31px 0px 0px'
  },
};

export const FooterContainer = ({ content }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      style={styles.section}
    >
      <Hidden smDown>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", margin: "49px 0px 50px" }}
        >
          {content.title}
        </Grid>
        <Grid item xs={10} md={3}>
          <div style={styles.items}>
            <img src={mailIcon} alt="mail icon" style={styles.icon} />
            <p style={{ ...styles.paragraph, fontStyle: "italic" }}>
              contacto@discoverynow.ai
            </p>
          </div>
        </Grid>
        <Grid item xs={10} md={3}>
          <div style={styles.items}>
            <img src={phoneIcon} alt="mail icon" style={styles.icon} />
            <p style={styles.paragraph}>+569 5019 1612</p>
          </div>
        </Grid>
        <Grid item xs={10} md={3}>
          <div style={styles.items}>
            <img src={locationIcon} alt="mail icon" style={styles.icon} />
            <p style={styles.paragraph}>
              La capitania 80, dpto 108. Las Condes, Santiago
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", margin: "65px 0px 25px" }}
        >
          <img src={title} alt="DISCOVERY" style={styles.imgTitle} />
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid item xs={8} style={{ textAlign: "center", marginTop: "31px" }}>
          {content.title}
        </Grid>
        <Grid item xs={7} md={3}>
          <div style={styles.itemsMobile}>
            <img src={mailIcon} alt="mail icon" style={styles.iconMobile} />
            <p style={{ ...styles.paragraphMobile, fontStyle: "italic" }}>
              contacto@discoverynow.ai
            </p>
          </div>
        </Grid>
        <Grid item xs={7} md={3}>
          <div style={styles.itemsMobile}>
            <p style={styles.paragraphMobile}>
              <img src={phoneIcon} alt="mail icon" style={styles.iconMobile} />
              +569 5019 1612
            </p>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div style={styles.itemsMobile}>
            <img src={locationIcon} alt="mail icon" style={styles.iconMobile} />
            <p style={styles.paragraphMobile}>
              La capitania 80, dpto 108. Las Condes, Santiago
            </p>
          </div>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", margin: "59px 0px 38px" }}>
          <img src={title} alt="DISCOVERY" style={styles.imgTitle} />
        </Grid>
      </Hidden>
    </Grid>
  );
};
