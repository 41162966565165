import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "./Styles";

export const Desktop = ({content}) => {
  const classes = useStyles();

  const {links, contact} = content;

  const scrollToContact = () => {
    window.scrollTo(0, 3000);
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="flex-end"
    >
      {links.map((item, index) => (
        <p className={classes.paragraph} key={`navbarItem_${index}`}>
          {!item.upperTitle ? (
            <NavLink
              activeClassName={classes.navlinkActiveDesktop}
              className={`nav-item nav-link ${classes.navLink}`}
              exact
              to={item.to}
            >
              {item.title}
            </NavLink>
          ) : (
            <>
              <span className={classes.upperTitle}>{item.upperTitle}</span>
              <br />
              <NavLink
                activeClassName={classes.navlinkActive}
                className={`nav-item nav-link ${classes.navLink}`}
                exact
                to={item.to}
              >
                <span className={classes.title}>{item.title}</span>
              </NavLink>
            </>
          )}
        </p>
      ))}
      <p className={classes.paragraph} onClick={scrollToContact}>
        {contact.title}
      </p>
    </Grid>
  );
};
