import { homePage } from "./enPages/home";
import { productPage } from "./enPages/product";
import { formContact } from "./enPages/formContact";
import { footerComponent } from "./enPages/footer";
import {
  navbarComponentDesktop,
  navbarComponentMobile,
} from "./enPages/navbar";

export const en = {
  homePage,
  productPage,
  formContact,
  navbarComponentDesktop,
  navbarComponentMobile,
  footerComponent,
};
