const homePageText = {
  header: {
    headerParagraph:
      "Somos una empresa dedicada a las soluciones digitales basadas en inteligencia artificial al servicio del bienestar de operadores y operadoras, y KPI’s de procesos.",
  },
  about: {
    about1Title: "QUIENES SOMOS",
    about1Paragraph1:
      "Somos un equipo multifuncional, con foco en entregables orientados a",
    about1ParagraphSpan1: "Key Value Drivers (KVD) y/o Key Cost Drivers (KCD),",
    about1Paragraph2: "mediante un alcance",
    about1ParagraphSpan2: "End to End,",
    about1Paragraph3:
      "que permite obtener resultados claros, valiosos y verificables en su impacto.",
    about2Title: "QUE HACEMOS",
    about2Paragraph:
      "Buscamos patrones y anomalías a partir de data histórica y/o en línea, estructurada y no estructurada (semántica, numérica, imagen, entre otras) para visualizar vectores de desempeño, con la finalidad de modelar conductas de personas y equipos (máquinas) mediante el direccionamiento de acciones específicas, personalizadas y automáticas a cientos de usuarios.",
  },
  product: {
    title1: "Nuestros",
    title2: "PRODUCTOS",
    subtitle: "¿Cómo?",
    paragraph1:
      "Discovery es un sistema basado en inteligencia artificial (IA) que permite predecir y disminuir la ocurrencia de fatalidades, accidentes de gravedad y pérdidas de tiempo operacional en diferentes industrias.",
    paragraph2:
      "Discovery tiene una base de datos de seguridad de 62.000 trabajadores, se está aplicando en Chile, Canadá, Estados Unidos y Australia.",
    paragraph3:
      "Discovery gestiona las observaciones de seguridad y riesgos críticos realizadas periódicamente en la industria, con información estructurada y no estructurada (campos de texto abierto), generando un análisis de los riesgos más relevantes presentes y asignándoles una probabilidad de ocurrencia.",
    buttonText: "Conoce más",
  },
  partners: {
    title1: "Nuestros",
    title2: "COLABORADORES",
  },
};

const productPageText = {
  header: {
    title1: "CONECTA CON",
    title2: "NUESTRA",
    title2Span: "SEGURIDAD",
    paragraph: "Detección de riesgos en la industria utilizando inteligencia artificial"
  },
  description: {
    principalParagraphSpan: "El valor de la salud y bienestar de una persona",
    principalParagraph: "es incalculable, debiendo ser el retorno integro al hogar de sus trabajadores, el principal indicador de toda empresa.",
    paragraph: "A pesar de ello, diferentes industrias incurren en perdidas operacionales millonarias todos los años, producto de enfermedades, accidentes e incidentes laborales, sumado a una cantidad importante de recursos destinados al procesamiento de información para detectar futuras mejoras en los procedimientos de seguridad de la industrial.",
    title: "¿Cómo solucionamos esto?",
    solution1titleSpan: "Observaciones",
    solution1title: "en terreno",
    solution1Paragraph: " Ayudamos a gestionar la cultura de seguridad de tu empresa adoptando periódicamente la realización por parte de personal interno de observaciones en terreno de procedimientos en diferentes áreas.",
    solution2title1: "Mapa de",
    solution2title1Span: "sectores",
    solution2title2: "de la industria",
    solution2Paragraph: "Generamos un mapa de calor según procedimientos y zonas geográficas de las observaciones en terreno realizadas, permitiendo su clasificación.",
    solution3Title: "Estandarización de",
    solution3TitleSpan: "riesgos",
    solution3Paragraph: "Detectamos y estandarizados los riegos asociado a cada uno de los procesos y zonas del mapa de calor, permitiendo la captura de data y generando algoritmos de inteligencia artificial.",
  },
  product: {
    paragraph: "Utilizando modelos de inteligencia artificial y Natural Lenguaje Processing, Discovery permite detectar los riesgos dentro de cada operación que pudieran generar potenciales eventos críticos, mediante análisis de las observaciones de seguridad y riesgos críticos realizados por los mismo trabajadores, para ser abordados con verificaciones en terreno, generando leading indicators, para impactar en la reducción del TRIF y entregando recomendaciones a cada operador, buscando mejorar su verificaciones en terreno.",
    titleModules: "Discovery posee 4 módulos:",
    module1: "Gestión y asignación de observaciones de seguridad.",
    module2: "Mapa de calor de sectores críticos en la industria.",
    module3: "Estandarización y verificación de calidad de observaciones de seguridad.",
    module4: "Detección de probabilidad ocurrencia por riesgo riesgo crítico.",
    highlight: "Detectamos huellas de futuros accidentes para permitir tu regreso a casa."
  },
  value: {
    title: "Propuesta de valor",
    value1Title: "Programación de actividades en terreno",
    value1Paragraph: "Planifica de manera automatizada según criticidad las actividades a realizar y se asignan a los trabajadores acorde su disponibilidad.",
    value1Element1: "Evita accidentes en la operación.",
    value1Element2: "Disminuye en al menos un 80% las HH utilizadas en el análisis y planificación de actividades de verificación en terreno.",
    value2Title: "Medición de calidad de observaciones en terreno",
    value2Paragraph: "Determina la calidad de las observaciones en terreno realizadas, generando un feedback personalizado a cada operador.",
    value2Element1: "Aumenta la calidad de las observaciones.",
    value2Element2: "Evita copia de observaciones.",
    value2Element3: "Mejora la cultura de seguridad en la faena.",
  },
  forWhom: {
    title: "¿Para quién?",
    paragraph: "Discovery, con 4 años de experiencia en el mercado, esta diseñado para ser utilizado por cualquier industria o empresa cuyo interés sea evitar posibles enfermedades, accidentes e incidentes en sus operaciones, manteniendo a sus trabajadores y trabajadoras como eje principal de su desarrollo, ayudando en su retorno seguro a sus hogares.",
    paragraphSpan: "Juntos podemos apoyar a la reducción de accidentes en la industria y la gestión de cambio requerida."
  }
};

const formContactText = {
  upperTitle: "¿Quieres saber más?",
  title: "CONTÁCTANOS",
  paragraph: "Escríbenos y nos contactaremos contigo a la brevedad.",
  textfieldName: "Nombre",
  textfieldEmail: "Correo",
  textfieldMessage: "Mensaje",
  textButton: "Enviar",
  textButtonAlert: "Revisar"
}

const menuText = {
  links: [
    {
      to: "/",
      title: "Inicio"
    },
    // {
    //   title: "Nosotros",
    //   to: "/about"
    // },
    {
      upperTitle: "Producto",
      title: "DISCOVERY",
      to: "/product"
    }
  ],
  contact: {
      title: "Contacto"
    }
}

const footerText = {
  title: "Información de contacto"
}

export { homePageText, productPageText, formContactText, menuText, footerText };
