import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { NavbarContainer } from "../NavbarContainer";
import logoProductoDiscovery from "../../constants/images/logoProductoDiscovery.png";
import backgroundHeader from "../../constants/images/backgroundHome.png";

const headerStyles = {
  sectionDesktop: {
    height: "95vh",
    background: `linear-gradient(180deg, #0E253C 16.15%, rgba(14, 37, 60, 0.49) 80%, rgba(14, 37, 60, 0.46) 100%), url(${backgroundHeader})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // boxShadow: '0px 10px 10vh rgba(50, 69, 87, 0.61)'
  },
  sectionMobile: {
    height: "95vh",
    background: `linear-gradient(180deg, #0E253C 16.15%, rgba(14, 37, 60, 0.49) 80%, rgba(14, 37, 60, 0.46) 100%), url(${backgroundHeader})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "25%",
    // boxShadow: '0px 10px 10vh rgba(50, 69, 87, 0.61)'
  },
  logo: { height: "10vh" },
  content: { height: "70vh", display: "flex", alignItems: "center" },
  sectionBottom: { height: "20vh" },
};

export const HeaderContainer = ({ content, navbarContent, navbarContentDesktop }) => {

  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={headerStyles.sectionDesktop}
        >
          <Grid item xs={12}>
            <NavbarContainer menuItemsDesktop={navbarContentDesktop}/>
          </Grid>
          <Grid item xs={12} style={headerStyles.content}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={5}>
                {content.titleDesktop}
              </Grid>
              <Grid
                item
                md={5}
                style={{
                  height: "70vh",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                {content.paragraphDesktop}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={headerStyles.sectionBottom}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid item xs={1} />
              <Hidden smDown>
                <Grid item xs={1}>
                  <img
                    src={logoProductoDiscovery}
                    alt="iPadHeader"
                    style={headerStyles.logo}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={headerStyles.sectionMobile}
        >
          <Grid item xs={12}>
            <NavbarContainer menuItemsMobile={navbarContent} />
          </Grid>
          <Grid item xs={12} style={headerStyles.content}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10}>
                {content.titleMobile}
              </Grid>
              <Grid item xs={10}>
                {content.paragraphMobile}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={headerStyles.sectionBottom}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid item xs={1} />
              <Hidden smDown>
                <Grid item xs={1}>
                  <img
                    src={logoProductoDiscovery}
                    alt="iPadHeader"
                    style={headerStyles.logo}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
