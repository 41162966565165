import { navbarStyles } from "../../../styles/sharedStyle";
import { menuText } from "./esText";

import aboutIcon from "../../images/aboutIcon.svg";
import contactIcon from "../../images/contactIcon.svg";
import homeIcon from "../../images/homeIcon.svg";
import productIcon from "../../images/productIcon.svg";


const navbarComponentMobile = {
  links: [
    {
      to: menuText.links[0].to,
      title: (
        <div style={navbarStyles.item}>
          <p style={navbarStyles.text}>{menuText.links[0].title}</p>
          <img src={homeIcon} alt="home icon" style={navbarStyles.icon} />
        </div>
      )
    },
    // {
    //   to: menuText.links[1].to,
    //   title: (
    //     <div style={navbarStyles.item}>
    //       <p style={navbarStyles.text}>{menuText.links[1].title}</p>
    //       <img src={aboutIcon} alt="about icon" style={navbarStyles.icon} />
    //     </div>
    //   )
    // },
    {
      to: menuText.links[1].to,
      title: (
        <div style={navbarStyles.item}>
          <p style={navbarStyles.text}>{menuText.links[1].upperTitle}</p>
          <img src={productIcon} alt="product icon" style={navbarStyles.icon} />
        </div>
      )
    }
  ],
  contact: {
    title: (
      <div style={navbarStyles.item}>
        <p style={navbarStyles.text}>{menuText.contact.title}</p>
        <img src={contactIcon} alt="contact icon" style={navbarStyles.icon} />
      </div>
    )
  }
};

const navbarComponentDesktop = {...menuText};

export { navbarComponentMobile, navbarComponentDesktop };
 