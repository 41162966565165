import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const HeaderStyles = makeStyles((theme) => ({
  sectionHeader: {
    textAlign: 'center'
  },
}));

export const DescriptionSolution = ({ content }) => {
  const headerStyles = HeaderStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      className={headerStyles.sectionHeader}
    >
      <Grid item xs={12}>
        {content.title}
      </Grid>
      <Grid item xs={12}>
        {content.image}
      </Grid>
      <Hidden smDown>
        <Grid item xs={12}>
          {content.paragraph}
        </Grid>
      </Hidden>
    </Grid>
  );
};
