import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../styles/colors";

const headerStyles = {
  sectionDesktop: {
    background: COLORS.blue_2,
  },
  logo: { height: "10vh" },
  content: { height: "70vh", display: "flex", alignItems: "center" },
  sectionBottom: { height: "20vh" },
};

export const AboutContainer = ({ content }) => {
  return (
    <>
      <Hidden smDown>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={headerStyles.sectionDesktop}
        >
          <Grid item xs={12}>
            {/* agregar imagen superior */}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={6}>
                {content.imgDesktop}
              </Grid>
              <Grid item md={6} style={{ padding: "10% 5%", marginLeft: '-100px' }}>
                {content.title}
                {content.aboutsDesktop.map((about, index) => (
                  <div key={`about_${index}`}>
                    {about.title}
                    {about.paragraph}
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={headerStyles.sectionDesktop}
        >
          <Grid item xs={12}>
            {/* agregar imagen superior */}
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={6}>
                {content.imgMobile}
              </Grid>
              <Grid item md={6} style={{ padding: "10%" }}>
                {content.aboutsMobile.map((about, index) => (
                  <div key={`about_${index}`}>
                    {about.title}
                    {about.paragraph}
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
