import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import productDiscovery from "../../constants/images/productDiscoveryES.svg";
import COLORS from "../../styles/colors";

const styles = {
  section: {
    background: COLORS.white,
    padding: "5vh 0px",
  },
};

export const ProductDetails = ({ content }) => {
  return (
    <Grid
      container
      direction="row-reverse"
      justify="center"
      alignItems="center"
      style={styles.section}
    >
      <Hidden smDown>
        <Grid item xs={10} md={6} style={{ textAlign: "right" }}>
          <img
            src={productDiscovery}
            alt="Discovery Blue"
            style={{ width: "30%" }}
          />
          {content.paragraphDesktop}
          {content.titleModulesDesktop}
          {content.modulesDesktop.map((module, index) => (
            <div key={`module_${index}`}>{module}</div>
          ))}
          {content.highlightDesktop}
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid item xs={10} md={5}>
          <div style={{ textAlign: "center" }}>
            <img
              src={productDiscovery}
              alt="Discovery Blue"
              style={{ width: "60%" }}
            />
          </div>
          {content.paragraphMobile}
          {content.titleModulesMobile}
          {content.modulesMobile.map((module, index) => (
            <div key={`module_${index}`}>{module}</div>
          ))}
          {content.highlightMobile}
        </Grid>
      </Hidden>

      <Hidden smDown>
        <Grid item xs={1} />
        <Grid item xs={10} md={4} style={{ textAlign: "center" }}>
          {content.productImageDesktop}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={10} md={5} style={{ textAlign: "center" }}>
          {content.productImageMobile}
        </Grid>
      </Hidden>
    </Grid>
  );
};
