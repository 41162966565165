import { TextField, Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  upperTitle: {
    color: "#A1B9BE",
    fontSize: "24px",
    fontWeight: "500",
    letterSpacing: "0.15em",
    fontFamily: "Archivo, sans-serif",
    margin: "0px",
  },
  title: {
    color: "#F0F0F0",
    fontSize: "36px",
    fontWeight: "400",
    letterSpacing: "-0.02em",
    fontFamily: "Archivo Black, sans-serif",
    margin: "10px 0px",
  },
  paragraphTitleMobile: {
    color: "#F0F0F0",
    fontSize: "20px",
    fontWeight: "300",
    letterSpacing: "-0.02em",
    fontStyle: "italic",
    margin: "0px 0px 50px 0px",
  },
  paragraphTitleDesktop: {
    color: "#F0F0F0",
    fontSize: "24px",
    fontWeight: "200",
    letterSpacing: "0em",
    fontStyle: "italic",
    margin: "0px",
  },
  bottomParagraph: {
    color: "#F0F0F0",
    fontSize: "30px",
    letterSpacing: "0.1em",
    textAlign: "right",
  },
  imgFooter: {
    maxHeight: "35px",
    margin: "80px 0px 30px 0px",
  },
  messageFormSend: {
    color: "#F0F0F0",
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "300",
  },
  messageFormError: {
    color: "#F0F0F0",
    fontSize: "30px",
    textAlign: "center",
  },
  messageFormSuccess: {
    color: "#F0F0F0",
    fontSize: "30px",
    textAlign: "center",
  }
}));

const StyledButton = withStyles({
  root: {
    background: "#324557",
    borderRadius: 99,
    border: 0,
    color: "#A1B9BE",
    height: 46,
    width: 160,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const StyledTextFieldDesktop = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: "#F0F0F0",
    height: 48,
    margin: "0px 0px 30px 0px",
    width: "100%",
    "& label.Mui-focused": {
      color: "#F0F0F0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F0F0F0",
    },
    "& label": {
      color: "#F0F0F0",
    },
    "& input": {
      color: "#F0F0F0",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#F0F0F0",
    },
  },
})(TextField);

const StyledTextFieldMobile = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: "#F0F0F0",
    height: 48,
    margin: "35px 0px",
    width: "100%",
    "& label.Mui-focused": {
      color: "#F0F0F0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F0F0F0",
    },
    "& label": {
      color: "#F0F0F0",
    },
    "& input": {
      color: "#F0F0F0",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#F0F0F0",
    },
  },
})(TextField);

export { useStyles, StyledButton, StyledTextFieldDesktop, StyledTextFieldMobile };
